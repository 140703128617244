import type { Entities } from '@inwink/entities/entities';
import type { States } from '../services';
import * as AppModules from '../../routes/appmodules';

function activity() {
    return import(/* webpackChunkName: "mod-activitytracking" */ "../activityservice");
}

interface IConsentRegistration {
    getConsent(trackingid, eventid, websiteid, tracking, consent, dispatch, getState);
}

const _g = global as any;

export interface ITrackerDefinition {
    isAvailable: (
        tracking: Entities.IEventDetailConfigurationTracking
    ) => boolean,
    isAllowed: (
        consentStatus: States.IConsentStatus,
        tracking: Entities.IEventDetailConfigurationTracking,
        context?: string
    ) => boolean,
    setup: (
        dispatch,
        getState: () => States.IAppState,
        eventid: string,
        websiteid: string,
        trackers: States.ITracker[],
        cmp: States.IConsentTracking,
        tracking: Entities.IEventDetailConfigurationTracking
    ) => Promise<void>
}

export const allTrackers : Record<States.ISupportedTrackers, ITrackerDefinition> = {
    "tk-ga": {
        isAvailable: (tracking) => {
            return (!!(tracking?.ga4) || !!(tracking?.googleAnalytics));
        },
        isAllowed: (consentStatus, tracking, context) => {
            if (context === 'axeptio' && consentStatus.details?.find((detail) => detail.toLowerCase() === 'google_analytics')) {
                return true;
            }

            if (
                context === 'onetrust'
            ) {
                if (
                    tracking?.googleAnalyticsCookieCategoryId
                    && consentStatus.details?.find((detail) => detail === tracking?.googleAnalyticsCookieCategoryId)
                ) {
                    return true;
                } else if (consentStatus.details?.find((detail) => detail === 'C0002')) {
                    return true;
                }
            }

            return (consentStatus.hasConsent || tracking.googleAnalyticsIsEssential);
        },
        setup: (dispatch, getState, eventid, websiteid, trackers, cmp, tracking) => {
            return AppModules.trackerGoogleAnalytics(dispatch, getState).then(
                (mod) => mod.googleAnalyticsScript("tk-ga", eventid, websiteid, trackers, cmp, tracking)
            );
        }
    },
    "tk-gtm": {
        isAvailable: (tracking) => {
            return !!(tracking?.googleTagManager);
        },
        isAllowed: (consentStatus, tracking, context) => {
            if (!(tracking?.googleTagManager)) {
                return false;
            }

            if (context === 'axeptio' && consentStatus.details?.find((detail) => detail.toLowerCase() === 'google_analytics')) {
                return true;
            }

            // context === 'onetrust' && consentStatus.details?.find((detail) => ['C0002', 'C0005'].indexOf(detail) !== -1);
            if (
                context === 'onetrust'
            ) {
                if (
                    tracking?.googleTagManagerCookieCategoryId
                    && consentStatus.details?.find((detail) => detail === tracking?.googleTagManagerCookieCategoryId)
                ) {
                    return true;
                } else if (consentStatus.details?.find((detail) => detail === 'C0002')) {
                    return true;
                }
            }

            return (consentStatus.hasConsent || tracking.googleTagManagerIsEssential);
        },
        setup: (dispatch, getState, eventid, websiteid, trackers, cmp, tracking) => {
            return AppModules.trackerGTM(dispatch, getState).then(
                (mod) => mod.googleTagManagerScript(
                    "tk-gtm", eventid, websiteid, trackers, cmp, tracking
                )
            );
        }
    },
    "tk-adobeDTM": {
        isAvailable: (tracking) => {
            return !!(tracking?.adobeDTM);
        },
        isAllowed: (consentStatus, tracking, context) => {
            if (context === 'axeptio' && consentStatus.details?.find((detail) => detail.toLowerCase() === 'adobe')) {
                return true;
            }

            if (
                context === 'onetrust'
            ) {
                if (
                    tracking?.adobeDTMCookieCategoryId
                    && consentStatus.details?.find((detail) => detail === tracking?.adobeDTMCookieCategoryId)
                ) {
                    return true;
                } else if (consentStatus.details?.find((detail) => detail === 'C0002')) {
                    return true;
                }
            }

            return (consentStatus.hasConsent || tracking.adobeDTMIsEssential);
        },
        setup: (dispatch, getState, eventid, websiteid, trackers, cmp, tracking) => {
            return AppModules.trackerAdobeDTM(dispatch, getState).then(
                (mod) => mod.adobeDTMScript("tk-adobeDTM", eventid, websiteid, trackers, cmp, tracking)
            );
        }
    },
    "tk-fbq": {
        isAvailable: (tracking) => {
            return !!(tracking?.facebookPixel);
        },
        isAllowed: (consentStatus, tracking, context: string) => {
            if (context === 'axeptio' && consentStatus.details?.find((detail) => detail.toLowerCase() === 'facebook_pixel')) {
                return true;
            }

            if (
                context === 'onetrust'
            ) {
                if (
                    tracking?.facebookPixelCookieCategoryId
                    && consentStatus.details?.find((detail) => detail === tracking?.facebookPixelCookieCategoryId)
                ) {
                    return true;
                } else if (consentStatus.details?.find((detail) => detail === 'C0004')) {
                    return true;
                }
            }

            return (consentStatus.hasConsent || tracking.facebookPixelIsEssential);
        },
        setup: (dispatch, getState, eventid, websiteid, trackers, cmp, tracking) => {
            return AppModules.trackerFBPixel(dispatch, getState).then(
                (mod) => mod.facebookPixelScript("tk-fbq", eventid, websiteid, trackers, cmp, tracking)
            );
        }
    },
    "tk-twq": {
        isAvailable: (tracking) => {
            return !!(tracking?.twitterPixel);
        },
        isAllowed: (consentStatus, tracking, context) => {
            if (context === 'axeptio' && consentStatus.details?.find((detail) => detail.toLowerCase() === 'twitter')) {
                return true;
            }

            if (
                context === 'onetrust'
            ) {
                if (
                    tracking?.twitterPixelCookieCategoryId
                    && consentStatus.details?.find((detail) => detail === tracking?.twitterPixelCookieCategoryId)
                ) {
                    return true;
                } else if (consentStatus.details?.find((detail) => detail === 'C0004')) {
                    return true;
                }
            }

            return (consentStatus.hasConsent || tracking.twitterPixelIsEssential);
        },
        setup: (dispatch, getState, eventid, websiteid, trackers, cmp, tracking) => {
            return AppModules.trackerTwitter(dispatch, getState).then(
                (mod) => mod.twitterPixelScript("tk-twq", eventid, websiteid, trackers, cmp, tracking)
            );
        }
    },
    "tk-hubspot": {
        isAvailable: (tracking) => {
            return !!(tracking?.hubspotAnalytics);
        },
        isAllowed: (consentStatus, tracking, context) => {
            if (context === 'axeptio' && consentStatus.details?.find((detail) => detail.toLowerCase() === 'hubspot')) {
                return true;
            }

            if (
                context === 'onetrust'
            ) {
                if (
                    tracking?.hubspotAnalyticsCookieCategoryId
                    && consentStatus.details?.find((detail) => detail === tracking?.hubspotAnalyticsCookieCategoryId)
                ) {
                    return true;
                } else if (consentStatus.details?.find((detail) => detail === 'C0002')) {
                    return true;
                }
            }

            return (consentStatus.hasConsent || tracking.hubspotAnalyticsIsEssential);
        },
        setup: (dispatch, getState, eventid, websiteid, trackers, cmp, tracking) => {
            return AppModules.trackerHubspot(dispatch, getState).then(
                (mod) => mod.hubspotAnalyticsScript("tk-hubspot", eventid, websiteid, trackers, cmp, tracking)
            );
        }
    },
    "tk-linkedin": {
        isAvailable: (tracking) => {
            return !!(tracking?.linkedIn);
        },
        isAllowed: (consentStatus, tracking, context) => {
            if (context === 'axeptio' && consentStatus.details?.find((detail) => detail.toLowerCase() === 'linkedin')) {
                return true;
            }

            if (
                context === 'onetrust'
            ) {
                if (
                    tracking?.linkedInCookieCategoryId
                    && consentStatus.details?.find((detail) => detail === tracking?.linkedInCookieCategoryId)
                ) {
                    return true;
                } else if (consentStatus.details?.find((detail) => detail === 'C0004')) {
                    return true;
                }
            }

            return (consentStatus.hasConsent || tracking.linkedInIsEssential);
        },
        setup: (dispatch, getState, eventid, websiteid, trackers, cmp, tracking) => {
            return AppModules.trackerLinkedin(dispatch, getState).then(
                (mod) => mod.linkedInScript("tk-linkedin", eventid, websiteid, trackers, cmp, tracking)
            );
        }
    },
    "tk-ensighten": {
        isAvailable: (tracking) => {
            return !!(tracking?.ensightenBootstraper);
        },
        isAllowed: (consentStatus, tracking) => {
            return (consentStatus.hasConsent || tracking.ensightenIsEssential);
        },
        setup: (dispatch, getState, eventid, websiteid, trackers, cmp, tracking) => {
            return AppModules.trackerEnsighten(dispatch, getState).then(
                (mod) => mod.ensightenScript("tk-ensighten", eventid, websiteid, trackers, cmp, tracking)
            );
        }
    },
    "tk-matomo": {
        isAvailable: (tracking) => {
            return !!(tracking?.matomoUrl && tracking?.matomoId);
        },
        isAllowed: (consentStatus, tracking, context) => {
            if (context === 'axeptio' && consentStatus.details?.find((detail) => detail.toLowerCase() === 'matomo')) {
                return true;
            }

            if (
                context === 'onetrust'
            ) {
                if (
                    tracking?.matomoCookieCategoryId
                    && consentStatus.details?.find((detail) => detail === tracking?.matomoCookieCategoryId)
                ) {
                    return true;
                } else if (consentStatus.details?.find((detail) => detail === 'C0004')) {
                    return true;
                }
            }

            return (consentStatus.hasConsent || tracking.matomoIsEssential);
        },
        setup: (dispatch, getState, eventid, websiteid, trackers, cmp, tracking) => {
            return AppModules.trackerMatomo(dispatch, getState).then(
                (mod) => mod.matomoScript("tk-matomo", eventid, websiteid, trackers, cmp, tracking)
            );
        }
    },
};

export const trackingServiceActions = {
    init: (
        trackingid:string,
        eventid: string,
        websiteid: string,
        tracking: Entities.IEventDetailConfigurationTracking,
        consent: Entities.IEventDetailConfigurationCompanionCookiesBanner
    ) => {
        return (dispatch, getState) => {
            return trackingServiceActions.registerConsentManager(
                trackingid, eventid, websiteid, tracking, consent
            )(dispatch, getState).then(() => {
                return trackingServiceActions.registerTrackers(
                    eventid, eventid, websiteid, tracking
                )(dispatch, getState);
            });
        };
    },

    registerConsentManager: (
        trackingid:string,
        eventid: string,
        websiteid: string,
        tracking: Entities.IEventDetailConfigurationTracking,
        consent: Entities.IEventDetailConfigurationCompanionCookiesBanner
    ) => {
        return (dispatch, getState) => {
            const isstartup = !!_g.INITIAL_STATE;
            const currentState: States.IAppState = getState();
            if (!isstartup && currentState.tracking?.consentTracking
                && currentState.tracking?.consentTracking?.trackingid === trackingid) {
                return Promise.resolve();
            }

            let initPromise: Promise<States.IConsentTracking> = null;
            if (consent?.cmp?.type && consent?.customCmpEnabled) {
                let p: Promise<IConsentRegistration>;
                if (consent?.cmp?.type === "didomi") {
                    p = AppModules.consentDidomi(dispatch, getState);
                } else if (consent?.cmp?.type === "quantcast") {
                    p = AppModules.consentQuantcast(dispatch, getState);
                } else if (consent?.cmp?.type === "cookiebot") {
                    p = AppModules.consentCookiebot(dispatch, getState);
                } else if (consent?.cmp?.type === "axeptio") {
                    p = AppModules.consentAxeptio(dispatch, getState);
                } else if (consent?.cmp?.type === "onetrust") {
                    p = AppModules.consentOneTrust(dispatch, getState);
                }

                if (p) {
                    initPromise = p.then(
                        (mod) => mod.getConsent(trackingid, eventid, websiteid, tracking, consent, dispatch, getState)
                    );
                }
            } else if (consent?.enabled) {
                initPromise = AppModules.consentInwink(dispatch, getState).then(
                    (mod) => mod.getConsent(trackingid, eventid, websiteid, tracking, consent, dispatch, getState)
                );
            }

            if (!initPromise) {
                initPromise = Promise.resolve({
                    trackingid: trackingid,
                    type: "default",
                    hasConsent: () => Promise.resolve({ hasConsent: true }),
                    ready: Promise.resolve(),
                    prepareTrackerScript: (tracker, script) => {
                        const res = Object.assign({}, script);
                        if (script.innerContent) {
                            res.innerContent = script.innerContent.replace("{injection}", "");
                        }
                        return res;
                    },
                    showConsent: () => {}
                });
            }

            return initPromise.then((consentmgr) => {
                dispatch({
                    type: "TRACKING_CONSENTMGR",
                    payload: consentmgr
                });
            });
        };
    },

    getAllTrackers: (
        trackingid:string,
        eventid: string,
        websiteid: string,
        tracking: Entities.IEventDetailConfigurationTracking,
        consentStatus: States.IConsentStatus
    ) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState) => {
            const promises = [];
            const currentState: States.IAppState = getState();
            const trackers: States.ITracker[] = currentState.tracking?.trackers ? [...currentState.tracking.trackers] : [];
            const currentCMP = currentState?.tracking?.consentTracking;

            if (
                tracking && !!tracking.applicationInsights
                && (consentStatus.hasConsent || tracking.applicationInsightsIsNonEssential)
            ) {
                appInsightTracker(eventid, websiteid, trackers);
            }

            Object.keys(allTrackers).forEach((trackerId: States.ISupportedTrackers) => {
                const definition: ITrackerDefinition = allTrackers[trackerId];
                if (definition.isAvailable(tracking)) {
                    let allowed = false;
                    if (currentCMP?.isTrackerAllowed) {
                        allowed = currentCMP.isTrackerAllowed(trackerId, tracking);
                    } else {
                        allowed = definition.isAllowed(consentStatus, tracking);
                    }

                    if (allowed) {
                        promises.push(definition.setup(dispatch, getState, eventid, websiteid, trackers, currentCMP, tracking));
                    }
                }
            });

            return Promise.all(promises).then(() => {
                const alltrackers = addCustomTrackers(
                    eventid,
                    websiteid,
                    trackers,
                    consentStatus,
                    currentCMP
                );

                return alltrackers;
            });
        };
    },

    registerTrackers: (
        trackingid:string,
        eventid: string,
        websiteid: string,
        tracking: Entities.IEventDetailConfigurationTracking
    ) => {
        return (dispatch, getState) => {
            const state: States.IAppState = getState();

            return state.tracking.consentTracking.hasConsent().then((consent) => {
                return trackingServiceActions.getAllTrackers(
                    trackingid,
                    eventid,
                    websiteid,
                    tracking,
                    consent
                )(dispatch, getState).then((alltrackers) => {
                    dispatch({
                        type: "TRACKING_INIT",
                        payload: {
                            trackers: alltrackers,
                            trackingid: trackingid,
                            nonEssentialRegistered: false
                        }
                    });

                    return alltrackers;
                });
            });
        };
    },

    trackingStatus() {
        return (dispatch, getState) => {
            const state = getState() as States.IAppState;

            if (state.tracking?.consentTracking) {
                return state.tracking.consentTracking.hasConsent().then((consent) => {
                    if (inwink.tracking && inwink.tracking.trackers && inwink.tracking.trackers.length) {
                        const eventId = state.event.eventid;
                        const websiteId = (!eventId ? state.rootwebsite.websiteid : null);
                        const trackers = addCustomTrackers(
                            eventId,
                            websiteId,
                            state.tracking.trackers,
                            consent,
                            state.tracking.consentTracking
                        );
                        if (trackers !== state.tracking.trackers) {
                            dispatch({ type: "TRACKING_UPDATE",
                                payload: {
                                    trackers: trackers
                                } });
                        }
                    }

                    return {
                        nonEssentials: consent
                    };
                });
            }

            return Promise.resolve({});
        };
    },

    checkImplicitConsent() {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            if (state.tracking.consentTracking.implicitConsent) {
                state.tracking.consentTracking.implicitConsent();
            }
        };
    },

    trackEvent(category: string, action: string, label?: string) {
        return (dispatch, getState) => {
            return tracker.trackAction(dispatch, getState, category, action, label);
        };
    }
};

export const tracker = {
    trackPage(
        dispatch: any,
        getState: () => States.IAppState,
        location,
        fulllocation: States.ILocation,
        pageTitle: string
    ) {
        const state = getState();
        if (state.event?.detail?.configuration?.companion?.cookiesWarning?.implicitConsentEnabled) {
            setTimeout(() => {
                trackingServiceActions.checkImplicitConsent()(dispatch, getState);
            }, 100);
        }
        activity().then((mod) => mod.userActivityActions.trackCurrentPage()(dispatch, getState));
        state.tracking.trackers.forEach((t) => {
            try {
                if (t.trackPage) { t.trackPage(location, fulllocation, pageTitle); }
            } catch (ex) {
                console.error(ex);
            }
        });
    },

    trackAction(dispatch: any, getState: () => States.IAppState, category: string, action: string, label?: string) {
        const state = getState();
        activity().then((mod) => mod.userActivityActions.trackActivity("event", category, action, label)(dispatch, getState));

        state.tracking.trackers.forEach((t) => {
            try {
                if (t.trackAction) { t.trackAction(category, action, label); }
            } catch (ex) {
                console.error(ex);
            }
        });
    }
};

function addCustomTrackers(
    eventid: string,
    websiteid: string,
    trackers: States.ITracker[],
    consentStatus : States.IConsentStatus,
    cmp: States.IConsentTracking
) {
    let newtrackers = trackers;
    if (inwink.tracking && inwink.tracking.trackers && inwink.tracking.trackers.length) {
        newtrackers = trackers ? [...trackers] : [];
        inwink.tracking.trackers.forEach((currenttracker) => {
            if (currenttracker.isAllowed) {
                if (!currenttracker.isAllowed(cmp, consentStatus)) {
                    return;
                }
            } else if (!consentStatus.hasConsent && !currenttracker.isEssential) {
                return;
            }

            if (currenttracker.script?.id){
                const existing = newtrackers.filter((t) => t.script?.id === currenttracker.script.id)[0];
                if (!existing) {
                    if (currenttracker.prepareScript) {
                        currenttracker.prepareScript(cmp, consentStatus);
                    }

                    newtrackers.push({
                        ...currenttracker,
                        partitionId: eventid,
                        websiteId: websiteid
                    });
                }
            }
        });
    }

    return newtrackers;
}

declare let appInsights;
function appInsightTracker(
    eventid: string,
    websiteid: string,
    trackers : States.ITracker[]
) {
    const trackerId = "tk-ai_" + (eventid || "") + (websiteid || "");
    const existing = trackers.filter((t) => t.script && t.script.id === trackerId)[0];
    if (existing) return;

    const instrKey = (typeof inwink !== "undefined" && inwink && inwink.config && inwink.config.appinsight) || InWinkAppInsight;
    if (instrKey) {
        const aitracker: States.ITracker = {
            partitionId: eventid,
            websiteId: websiteid,
            script: {
                id: trackerId,
                // eslint-disable-next-line max-len
                innerContent: `var appInsights=window.appInsights||function(config){ function i(config){t[config]=function(){var i=arguments;t.queue.push(function(){t[config].apply(t,i)})}}var t={config:config},u=document,e=window,o="script",s="AuthenticatedUserContext",h="start",c="stop",l="Track",a=l+"Event",v=l+"Page",y=u.createElement(o),r,f;y.src=config.url||"https://az416426.vo.msecnd.net/scripts/a/ai.0.js";u.getElementsByTagName(o)[0].parentNode.appendChild(y);try{t.cookie=u.cookie}catch(p){}for(t.queue=[],t.version="1.0",r=["Event","Exception","Metric","PageView","Trace","Dependency"];r.length;)i("track"+r.pop());return i("set"+s),i("clear"+s),i(h+a),i(c+a),i(h+v),i(c+v),i("flush"),config.disableExceptionTracking||(r="onerror",i("_"+r),f=e[r],e[r]=function(config,i,u,e,o){var s=f&&f(config,i,u,e,o);return s!==!0&&t["_"+r](config,i,u,e,o),s}),t }({
                    instrumentationKey:"${instrKey}"
                });
                
                window.appInsights=appInsights;
                appInsights.trackPageView();`
            },

            csp: {
                "script-src": ["*.msecnd.net"],
                "connect-src": ["*.visualstudio.com"]
            },

            trackPage(location) {
                if (typeof appInsights === "undefined") return;

                appInsights.trackPageView(location);
            },

            trackAction(category: string, action: string, label?: string) {
                if (typeof appInsights === "undefined") return;

                appInsights.trackEvent(category + ":" + action + (label ? ":" + label : ""));
            }
        };

        trackers.push(aitracker);
    }
}
